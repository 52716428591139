// Common
import './lib/common';

// Load Web components
import { SeguimientoHeader } from '../components/seguimiento-header/SeguimientoHeader.js';
import { SeguimientoFooter } from '../components/seguimiento-footer/SeguimientoFooter.js';

// Load libraries
import { normalizeProjectName } from './lib/util';

let userId;
let firebaseCRUD;
let firebaseLoginButton;
let activeUsers;
let proyectos;
let empByEmbajadas;
let employersByCapacidad;

const worker = new Worker('/js/lucipher-worker.js');

async function drawOnHollidays(hollidays) {
  const nidKeys = Object.keys(hollidays);
  const usersDeVacaciones = Object.values(hollidays);
  const deVacacionesByClients = {};
  for (let i = 0; i < usersDeVacaciones.length; i += 2) {
    let client1 = '';
    let client2 = '';
    if (empByEmbajadas[nidKeys[i]]) {
      const proyectName1 = (empByEmbajadas[nidKeys[i]]) ? empByEmbajadas[nidKeys[i]].proyecto : '';
      const proyectName2 = (empByEmbajadas[nidKeys[i + 1]]) ? empByEmbajadas[nidKeys[i + 1]].proyecto : '';
      try {
        const projs1 = normalizeProjectName(proyectName1).split(';');
        const projs2 = normalizeProjectName(proyectName2).split(';');
        // eslint-disable-next-line no-loop-func
        client1 = projs1.map((projcur) => `${(proyectos[projcur]) ? proyectos[projcur].cliente : ''}`).filter((v) => (v !== '')).join(', ');
        // eslint-disable-next-line no-loop-func
        client2 = projs2.map((projcur) => `${(proyectos[projcur]) ? proyectos[projcur].cliente : ''}`).filter((v) => (v !== '')).join(', ');
      } catch (e) {
        console.log(e);
      }
      deVacacionesByClients[client1] = deVacacionesByClients[client1] || [];
      deVacacionesByClients[client2] = deVacacionesByClients[client2] || [];
      deVacacionesByClients[client1].push(`${usersDeVacaciones[i].userName} (hasta el ${usersDeVacaciones[i].hollidayDateTo})`);
      if (usersDeVacaciones[i + 1]) {
        deVacacionesByClients[client2].push(`${usersDeVacaciones[i + 1].userName} (hasta el ${usersDeVacaciones[i + 1].hollidayDateTo})`);
      }
    } else {
      deVacacionesByClients['KAIROS DS, CLIENTE INTERNO'] = deVacacionesByClients['KAIROS DS, CLIENTE INTERNO'] || [];
      deVacacionesByClients['KAIROS DS, CLIENTE INTERNO'].push(`${usersDeVacaciones[i].userName} (hasta el ${usersDeVacaciones[i].hollidayDateTo})`);
    }
  }
  let i = 0;
  const deVacacionesHTML = Object.keys(deVacacionesByClients).map((client) => {
    const claseTD = ['blanco', 'gris'];
    const users = deVacacionesByClients[client];
    return /* HTML */`
    <tr class="${claseTD[++i % 2]}">
      <td class="client">${client}</td>
      <td class="list"><ul><li>${users.join('</li><li>')}</li></ul></td>
    </tr>
    `;
  }).join('');

  document.getElementById('devacacionesLayer').innerHTML = /* HTML */`<table>${deVacacionesHTML}</table>`;
}

function drawBirthdays(birthdays, birthdaysToday) {
  const TODAY = [new Date().getDate(), new Date().getMonth(), new Date().getFullYear()];
  const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  document.getElementById('today').innerHTML = /* HTML */`
    <div style="color:#ff7939; font-weight:bold; font-size:1.5rem;">${TODAY[0]} de ${meses[TODAY[1]]}</div>
    <div><b>Hoy es el cumpleaños de:</b><ul>${birthdaysToday}</ul></div>
  `;
  document.getElementById('cumpleañosLayer').innerHTML = `<table>${birthdays}</table>`;
}

async function calcBirthdays() {
  const efemerides = await firebaseCRUD.getData('/efemerides');
  const birthdaysEnc = efemerides.birthdays;
  const birthdaysTodayEnc = efemerides.birthdaysToday;
  const kaiversariosEnc = efemerides.kaiversarios;
  const kaiversariosTodayEnc = efemerides.kaiversariosToday;
  const incorporacionesEnc = efemerides.incorporacion;
  const incorporacionesTodayEnc = efemerides.incorporacionesToday;

  worker.postMessage({
    action: 'efemerides',
    data: {
      birthdays: birthdaysEnc,
      birthdaysToday: birthdaysTodayEnc,
      kaiversario: kaiversariosEnc,
      kaiversarioToday: kaiversariosTodayEnc,
      incorporacion: incorporacionesEnc,
      incorporacionesToday: incorporacionesTodayEnc,
    },
  });
}

function drawKaiversario(data) {
  // console.log(data);
  const {
    kaiversario = '', incorporacion = '', kaiversarioToday = '', incorporacionesToday = '',
  } = data;
  document.getElementById('kaiversarioLayer').innerHTML = /* HTML */`<table>${kaiversario}</table>`;
  document.getElementById('incorporacionLayer').innerHTML = /* HTML */`<table>${incorporacion}</table>`;
  document.getElementById('today').innerHTML += /* HTML */`
    <div><b>Hoy es el aniversario de:</b><ul>${kaiversarioToday}</ul></div>
    <div><b>Hoy se incorporan:</b><ul>${incorporacionesToday}</ul></div>
  `;
}

function drawKairoseros(dataEmployersOnHollidays) {
  const capacities = Object.keys(employersByCapacidad);
  let numEmployersCORPORATE = 0;
  // const mainCapacities = ['DEVELOPERS', 'AGILE', ' DEVOPS', 'UX', 'DATA', 'INNOV'];
  const corporateCapacities = ['CORPORATE', 'MARCA', 'TALENTO', 'OPERACIONE', 'SOPORTE', 'FINANZAS'];
  capacities.forEach((capacity) => {
    if (corporateCapacities.includes(capacity)) {
      numEmployersCORPORATE += employersByCapacidad[capacity].length;
    }
  });
  document.getElementById('today2').innerHTML = /* HTML */`
    <div></div>
    <div>
      <b>Kairoseros en activo:</b><span>${activeUsers.length}</span>
    </div>
    <div class="mini">
      <b>DEVs</b>${employersByCapacidad.DEVELOPERS.length}
      <b>AGILEs</b>${employersByCapacidad.AGILE.length}
      <b>DEVOPs</b>${employersByCapacidad.DEVOPS.length}
      <b>UXs</b>${employersByCapacidad.UX.length}
      <b>DATAs</b>${employersByCapacidad.DATA.length}
      <b>INNOVA</b>${employersByCapacidad.INNOV.length}
      <b>CORPORATE</b>${numEmployersCORPORATE}
    </div>
    <div>
      <b>Hoy están de vacaciones</b><span>${Object.keys(dataEmployersOnHollidays).length}<span>
    </div>
  `;
}

worker.addEventListener('message', (wev) => {
  if (wev.data.action === 'efemerides') {
    // console.log('response efemerides from worker', wev.data.data);
    const { data } = wev.data;
    drawBirthdays(data.birthdays, data.birthdaysToday);
    drawKaiversario(data);
  }

  if (wev.data.action === 'hollidays') {
    // console.log('response hollidays from worker', Object.keys(wev.data.data).length);
    drawOnHollidays(wev.data.data);
    drawKairoseros(wev.data.data);
  }
}, false);

function _reloadIfIsAnotherDay() {
  setInterval(() => {
    const now = new Date();
    if (now.getHours() === 0) {
      document.location.reload();
    }
  }, 1000 * 60 * 60);
}

async function init(ev) {
  worker.postMessage({ uid: firebaseLoginButton.uid });
  if (ev.detail.user && ev.detail.user.email.match(/kairosds.com$/)) {
    userId = ev.detail.user.email.replace(/@kairosds.com/, '').replace(/\./g, '_');
    calcBirthdays();
    document.getElementById('today').innerHTML = 'Desencriptando datos...';
  }
  activeUsers = await firebaseCRUD.getData('/views/employersByStatus/Activo');
  empByEmbajadas = await firebaseCRUD.getData('/views/employersWithEmbajadas');
  proyectos = await firebaseCRUD.getData('/proyectos');
  employersByCapacidad = await firebaseCRUD.getData('/views/employersByCapacity');
  const hollidays = await firebaseCRUD.getData('/hollidays');
  const empByStatus = await firebaseCRUD.getData('/views/employersByStatus');
  worker.postMessage({
    action: 'hollidays',
    data: {
      hollidays,
      empByStatus,
    },
  });
  _reloadIfIsAnotherDay();
}

function logged() {
  document.querySelector('#content').classList.remove('invisible');
  firebaseLoginButton = document.querySelector('firebase-loginbutton');
  firebaseCRUD = document.querySelector('firebase-crud');
  init({ detail: { user: firebaseLoginButton.dataUser } });
}

function wcReady(ev) {
  console.log('wc-ready', ev.detail.id);
  if (ev.detail.id === 'firebaseCrud') {
    logged();
  }
}

function seguimientoLoaded() {
  document.addEventListener('wc-ready', wcReady, { once: true });

  document.addEventListener('firebase-signout', () => {
    document.querySelector('#content').classList.add('invisible');
  });
}

window.addEventListener('load', seguimientoLoaded, false);
