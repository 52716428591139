// Copyright 2022 manufosela.
// SPDX-License-Identifier: MIT

export function normalizeProjectName(proyecto) {
  const tmp = proyecto.toLowerCase().trim()
    .replace(/\s/g, '_')
    .replace(/_-_/, '-')
    .replace(/\//g, '')
    .replace(/\./g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/&/g, '');
  const parts = tmp.split('-');
  parts[0] = parts[0].toUpperCase();
  return parts.join('-');
}
